import { addAlpha, colors, StyledIcon, TextStyles } from 'fitify-ui'
import { ButtonStyles } from 'fitify-ui-landing/src/components/Button/Button.Styles'
import {
  biggerPhoneUp,
  phone,
  biggerPhoneLandscape,
  BREAKPOINTS,
} from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css } from 'styled-components'

import { zIndexes } from '../../../theme/zIndexes'
import { StyledContainer } from '../../Container/Container.Styled'

export const StyledHeader = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 110px);

  ${phone(css`
    height: calc(100% - 260px);
    display: flex;
    flex-direction: column-reverse;
  `)};
`
export const StyledHeaderContainer = styled(StyledContainer)`
  align-items: start;
  display: flex;
  position: relative;
  height: 100%;
`

export const StyledHeaderWrapper = styled.div`
  z-index: 15;
  display: flex;
  max-width: 984px;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 120px;

  ${biggerPhoneLandscape(css`
    margin-top: 50px;
  `)};

  ${phone(css`
    margin-top: 0;
    justify-content: center;
  `)};

  div {
    ${phone(css`
      &:first-child {
        width: inherit;
      }
    `)};
  }

  ${TextStyles} {
    &:not(:first-child) {
      max-width: 420px;
      margin-top: 16px;
      margin-bottom: 32px;
      ${biggerPhoneLandscape(css`
        margin-top: 16px;
        margin-bottom: 24px;
        max-width: 350px;
      `)};
      ${phone(css`
        margin-top: 0;
        margin-bottom: 24px;
        max-width: 350px;
      `)};
    }

    &:nth-child(2) {
      ${phone(css`
        margin-bottom: 24px;
        max-width: 600px;
      `)};
    }

    &:nth-child(3) {
      ${phone(css`
        color: ${colors.Gray800};
      `)};
    }

    ${phone(css`
      max-width: 100%;
    `)};
  }

  ${ButtonStyles} {
    order: 5;
    justify-content: center;
    width: 100%;

    ${biggerPhoneUp(css`
      order: 0;
      width: fit-content;
      justify-content: flex-start;
    `)};
  }
`

export const StyledHeaderWrapperContent = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;

  ${ButtonStyles} {
    padding: 17px 32px;
    height: 56px;
    > span {
      font-size: 20px;
    }

    ${biggerPhoneLandscape(css`
      height: 48px;
      padding: 14.5px 28px;

      > span {
        font-size: 16px;
      }
    `)};

    ${phone(css`
      height: 48px;
      padding: 14.5px 0;

      > span {
        font-size: 16px;
      }
    `)};
  }
`

export const StyledMobileImage = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  margin-left: -24px;
  margin-right: -24px;
  width: calc(100% + 48px) !important;
  margin-bottom: 24px;
`

export const StyledHeaderBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  height: calc(100vh - 111px);
  width: 100%;
  overflow: hidden;

  ${phone(css`
    position: relative;
    display: inline-block;
    height: auto;
    width: auto;
  `)};

  ${biggerPhoneUp(css`
    min-height: 625px;

    ${biggerPhoneLandscape(css`
      min-height: 400px;
    `)};
  `)};
`

export const StyledHeaderBackgroundGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - 111px);
  width: 65%;
  overflow: hidden;
  opacity: 0.85;
  z-index: ${zIndexes.backgroundGradient};
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);

  ${phone(css`
    display: none;
  `)};

  ${biggerPhoneUp(css`
    min-height: 625px;
  `)};
`

export const StyledTrainerHeaderImage = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: 24px;
`

export const StyledTrainerHeaderItem = styled.div`
  width: 50%;

  ${TextStyles} {
    max-width: 394px;

    &:not(:first-child) {
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }
  ${phone(css`
    order: 1;
    width: 100% !important;
  `)};
`

export const StyledTrainerHeaderButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  @media ${BREAKPOINTS.MD.lte} {
    ${ButtonStyles} {
      width: 100%;
      justify-content: center;
    }
  }
`

export const StyledTrainerHeader = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  ${phone(css`
    order: 1;
    flex-direction: column;
  `)};
`

export const StyledRatingHeader = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: start;
  bottom: 120px;
  margin-bottom: 80px;

  ${biggerPhoneLandscape(css`
    position: relative;
    bottom: unset;
  `)};

  ${phone(css`
    position: relative;
    bottom: unset;
    align-self: center;
    margin-top: 40px;
    margin-bottom: 0;
    order: 5;
  `)};
`

export const StyledRatingHeaderItem = styled.div`
  margin-top: 48px;

  &:nth-child(1) {
    order: 1;
  }

  &:nth-child(2) {
    order: 2;
    margin-left: 14px;
    margin-top: 48px;
  }

  ${biggerPhoneLandscape(css`
    &:nth-child(n) {
      margin-top: 24px;
    }
  `)};

  ${phone(css`
    &:nth-child(n) {
      margin-top: 0;
    }
  `)};
`

export const StyledScrollDiv = styled.div`
  display: flex;
  z-index: 100;
  position: relative;
  align-self: flex-end;
  margin-bottom: 80px;

  ${phone(css`
    position: absolute;
    right: 16px;
    bottom: 16px;
    margin-bottom: 0;
  `)};
`

export const StyledScrollLink = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 100%;
  padding: 16px;
  background: ${addAlpha(colors.Gray100, 0.72)};
  backdrop-filter: blur(12px);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 1;
    background: ${addAlpha(colors.Gray300, 0.72)};
  }

  ${phone(css`
    width: 48px;
    height: 48px;
    padding: 12px 14px;
    margin: 0;

    ${StyledIcon} {
      width: 21px;
      height: 26px;
    }
  `)};
`
