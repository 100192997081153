import { Icon, Text, useImageSlider, useDeviceSize } from 'fitify-ui'
import { ThemeVariants } from 'fitify-ui/src/theme'
import { ImageTypes } from 'fitify-ui-landing/src/@types'
import { ELEMENT_IDS } from 'fitify-ui-landing/src/@types/constants'
import { size } from 'fitify-ui-landing/src/theme/breakpoints'
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import AnimatedTextWord from '../../animations/WordAnimation'

import {
  StyledHeader,
  StyledHeaderBackground,
  StyledHeaderBackgroundGradient,
  StyledHeaderContainer,
  StyledHeaderWrapper,
  StyledHeaderWrapperContent,
  StyledMobileImage,
  StyledRatingHeader,
  StyledRatingHeaderItem,
  StyledScrollDiv,
  StyledScrollLink,
} from './Header.Styled'

interface HeaderProps {
  headline: string
  subheadline: string
  variant: string
  headerImages: ImageTypes.Header[]
  button: React.ReactNode
  shouldShowRating?: boolean
}

const Header = ({
  headerImages,
  headline,
  subheadline,
  variant,
  button,
  shouldShowRating,
}: HeaderProps) => {
  const { t } = useTranslation()
  const currentImageIndex = useImageSlider(headerImages)
  const { windowWidth } = useDeviceSize()
  const theme = useTheme()
  const isDigital = theme.variant === ThemeVariants.DIGITAL

  const isPhoneBreakpoint = windowWidth <= size.phone
  const currentImage = headerImages[currentImageIndex]

  const handleScrollToAnimation = () => {
    const element = document.getElementById(ELEMENT_IDS.mobileAnimation)
    const navigation = document.getElementById(ELEMENT_IDS.navigation)

    if (element && navigation) {
      const navigationHeight = navigation.clientHeight

      window.scrollTo({
        top: element.offsetTop - navigationHeight,
        left: 0,
      })
    }
  }

  return (
    <StyledHeader>
      <StyledHeaderContainer>
        <StyledHeaderWrapper>
          <StyledHeaderWrapperContent>
            {isPhoneBreakpoint ? (
              <>
                <StyledMobileImage>
                  <AnimatePresence>
                    {headerImages.map((image, index) =>
                      currentImageIndex === index ? (
                        <motion.div
                          key={'headerImageDiv_' + index}
                          initial={index === 0 ? false : { opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 1 }}
                        >
                          {image.chatImage ? (
                            <motion.div
                              key={'chatImageDiv_' + index}
                              initial={
                                index === 0 ? false : { opacity: 1, left: -200 }
                              }
                              animate={{ opacity: 1, left: 16 }}
                              exit={{ opacity: 1, left: -200 }}
                              transition={{ duration: 1 }}
                              style={{
                                width: '30%',
                                height: '60%',
                                position: 'absolute',
                                top: '16px',
                                left: '16px',
                                zIndex: '1000',
                              }}
                            >
                              <Image
                                style={{ zIndex: 100 }}
                                priority
                                key={'chatImage_' + index}
                                src={image.chatImage}
                                layout={'fill'}
                                objectFit="contain"
                                alt={`Chat Image - ${index}`}
                              />
                            </motion.div>
                          ) : null}

                          <Image
                            priority
                            key={'headerImage_' + index}
                            src={image.headerImage}
                            layout={'fill'}
                            objectFit="cover"
                            alt={`Header Image - ${index}`}
                          />
                        </motion.div>
                      ) : null
                    )}
                  </AnimatePresence>

                  {isPhoneBreakpoint ? (
                    <StyledScrollDiv>
                      <Link href={'/#mobile-app-features'} scroll={false}>
                        <StyledScrollLink
                          aria-label={
                            isDigital
                              ? t('aria_label_scroll_down')
                              : t('hc_aria_label_scroll_down')
                          }
                        >
                          <Icon name={'arrow-down'} />
                        </StyledScrollLink>
                      </Link>
                    </StyledScrollDiv>
                  ) : null}
                </StyledMobileImage>

                <Text
                  as={'h1'}
                  variant={variant as TextTypesVariants}
                  html={t(headline)}
                />
              </>
            ) : (
              <AnimatedTextWord
                text={t(headline)}
                variant={variant as TextTypesVariants}
              />
            )}

            <Text as={'p'} variant={'header-paragraph'}>
              {t(subheadline)}
            </Text>

            {button ? button : null}

            {shouldShowRating ? (
              <StyledRatingHeader>
                <StyledRatingHeaderItem>
                  <Image
                    priority
                    src={'/assets/images/icons/app-of-the-day.svg'}
                    width={isPhoneBreakpoint ? 143 : 163}
                    height={isPhoneBreakpoint ? 32 : 38}
                    alt={`Fitify.com - App of the Day`}
                  />
                </StyledRatingHeaderItem>
                <StyledRatingHeaderItem>
                  <Image
                    priority
                    src={'/assets/images/icons/rating.svg'}
                    width={isPhoneBreakpoint ? 143 : 163}
                    height={isPhoneBreakpoint ? 32 : 38}
                    alt={`Fitify.com - Rating`}
                  />
                </StyledRatingHeaderItem>
              </StyledRatingHeader>
            ) : null}
          </StyledHeaderWrapperContent>
        </StyledHeaderWrapper>

        {!isPhoneBreakpoint ? (
          <StyledScrollDiv>
            <StyledScrollLink
              onClick={handleScrollToAnimation}
              aria-label={
                theme.variant === ThemeVariants.DIGITAL
                  ? t('aria_label_scroll_down')
                  : t('hc_aria_label_scroll_down')
              }
            >
              <Icon name={'arrow-down'} />
            </StyledScrollLink>
          </StyledScrollDiv>
        ) : null}
      </StyledHeaderContainer>

      {!isPhoneBreakpoint && (
        <StyledHeaderBackground>
          <StyledHeaderBackgroundGradient />
          <AnimatePresence>
            <motion.img
              key={'headerImage_' + currentImageIndex}
              initial={currentImageIndex > 0 ? { opacity: 0 } : {}}
              animate={currentImageIndex > 0 ? { opacity: 1 } : {}}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
              style={{
                position: 'absolute',
                minHeight: '100vh',
              }}
              src={currentImage.headerImage}
              srcSet={currentImage.headerImageSet}
              alt={`Header Image - ${currentImageIndex}}`}
            />
          </AnimatePresence>
        </StyledHeaderBackground>
      )}
    </StyledHeader>
  )
}

export default Header
