import { Text } from 'fitify-ui'
import { ThemeVariants } from 'fitify-ui/src/theme'
import { DataTypes } from 'fitify-ui-landing/src/@types'
import { useTranslation } from 'next-i18next'
import { Fade } from 'react-awesome-reveal'
import { useTheme } from 'styled-components'

import Container from '../../Container/Container'
import Section from '../../Section/Section'

import Review from './Review'
import { StyledReviews, StyledReviewsTitle } from './Reviews.Styled'

const Reviews = ({
  headline,
  items,
  isBusiness,
}: {
  headline: string
  items: DataTypes.ReviewItem[]
  isBusiness: boolean
}) => {
  const { t } = useTranslation()

  const theme = useTheme()

  return (
    <Section id={'reviews'}>
      <Container>
        <Fade triggerOnce>
          <StyledReviewsTitle $isBusiness={isBusiness}>
            <Text
              as={'h2'}
              variant={
                theme.variant === ThemeVariants.DIGITAL
                  ? 'business-title'
                  : 'condensed-title'
              }
            >
              {t(headline)}
            </Text>
          </StyledReviewsTitle>
          <StyledReviews $isBusiness={isBusiness}>
            {items.map((item, index: number) => {
              return (
                <Review
                  key={index}
                  name={item.name}
                  job={item.job}
                  description={item.description}
                />
              )
            })}
          </StyledReviews>
        </Fade>
      </Container>
    </Section>
  )
}

export default Reviews
