import { Text } from 'fitify-ui'
import { DataTypes } from 'fitify-ui-landing/src/@types'
import { ELEMENT_IDS } from 'fitify-ui-landing/src/@types/constants'
import Container from 'fitify-ui-landing/src/components/Container/Container'
import Section from 'fitify-ui-landing/src/components/Section/Section'
import { useTranslation } from 'next-i18next'
import { Fade } from 'react-awesome-reveal'

import { toolsData } from '@/data/tools'

import Tool from './Tool'
import { StyledTools, StyledToolsContent } from './Tools.Styled'

const Tools = () => {
  const { t } = useTranslation()

  return (
    <Section id={ELEMENT_IDS.tools}>
      <Container>
        <Fade triggerOnce>
          <StyledTools>
            <Text
              as={'h2'}
              variant={'tool-title'}
              style={{ marginBottom: '8px', textAlign: 'center' }}
            >
              {t('landing_equipment_block_headline')}
            </Text>
            <Text
              as={'p'}
              variant={'tool-subheadline'}
              style={{ textAlign: 'center' }}
            >
              {t('landing_equipment_block_subheadline')}
            </Text>
            <StyledToolsContent>
              {toolsData.map((item: DataTypes.ToolItem, index: number) => {
                return <Tool key={index} icon={item.icon} title={item.title} />
              })}
            </StyledToolsContent>
          </StyledTools>
        </Fade>
      </Container>
    </Section>
  )
}

export default Tools
